import React, { Component } from 'react'
import {Helmet} from 'react-helmet'

export default class About extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className = 'about_container' >
                <Helmet>
                    <title>About Plug</title>
                    <meta 
                        name='description'
                        content = 'Plug is a beauty app for black women to discover, share and achieve hairstyle inspirations by connecting them to the right stylists. We created and designed this app with a deep understanding of the struggle and hassle black women go through to get their hair done.'
                    />
                </Helmet>
                <h1>
                    About Us
                </h1>
                <p>
                    A wedding to attend. Two stressed out ladies.  A business idea.
                </p>
                <p>
                    Plug is a beauty app for black women to discover, share and achieve hairstyle inspirations by connecting them to the right stylists.
                </p>
                <p>
                    We created and designed this app with a deep understanding of the struggle and hassle black women go through to get their hair done.
                </p>
                <p>
                And most importantly, an even deeper understanding of what and how much our crowns mean to us which is why we put in the time and effort. 
                </p>
                <p>
                But it doesn’t have to be stressful, all the time. 
                </p>
                <p>
                This is for the woman who wants to look good and have an easy time doing it.  Plug is a one stop beauty app that provides convenience, information and personalization in purchasing beauty services. 
                </p>
                <p>
                We are intensely focused on making beauty experiences as convenient as possible. 
                </p>
                
                <p>
                You want a hairstyle, you get it. Simple. 
                </p>
                <p>
                To my black women, this is for you. Look and feel your best, effortlessly. 
                </p>
            </div>
        )
    }
}
