import { initializeApp } from "firebase/app";

import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
	apiKey: 'AIzaSyDAjW1C4aqYUMztj-W9NZEBbPpSmSIfI38',
	authDomain: 'plug-website.firebaseapp.com',
	// databaseURL: "https://plug-website.firebaseio.com",
	databaseURL: 'https://plug-user-app-default-rtdb.firebaseio.com',
	projectId: 'plug-website',
	storageBucket: 'plug-website.appspot.com',
	messagingSenderId: '483187552931',
	appId: '1:483187552931:web:32aeb675596fc075814516',
	measurementId: 'G-ZHNBJ4K14F',
};



export default initializeApp(firebaseConfig);;
