import React, { Component } from 'react'

export default class Feature extends Component {
    render() {
        return (
            <div className={`feature_container ${this.props.class}`}>
                <div className="feature">
                    <div className="feature_text">
                        <h3>{this.props.category}</h3>
                        <h2>{this.props.title}</h2>
                        <p dangerouslySetInnerHTML = {{__html: this.props.description}}/>
                    </div>
                    <div className="feature_image">
                        <img src={require(`../img/${this.props.icon}.png`)} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
