import React, { Component } from "react";
import clients from "../img/why_clients.png";
import money from "../img/why_money.png";
import commit from "../img/why_commit.png";
import how1 from "../img/how1.png";
import how2 from "../img/how2.png";
import how3 from "../img/how3.png";
import how4 from "../img/how4.png";
import how5 from "../img/how5.png";
import phone from "../img/earnings.png";
import lady from "../img/lady3.png";
import download from "../img/get_download.png";
import register from "../img/get_register.png";
import train from "../img/get_train.png";
import { Link } from "react-router-dom";

export default class Salonist extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="salonist_container">
        <div className="salonist_what_container">
          <div className="salonist_what">
            <h1>What is Plug?</h1>
            <p>
              Plug is a beauty app for black women to achieve hairstyles they
              desire by connecting them to the right stylists. <br /> <br />
              We match what the client wants to the services and skills you are
              offering. YOU get a new client and she gets a good stylist. It’s a
              win-win!
            </p>
          </div>
        </div>

        <div className="salonist_why_container">
          <div className="salonist_why">
            <h1>Why Plug?</h1>
            <div className="salonist_whys">
              <div className="salonist_why_single">
                <img src={clients} alt="" />
                <h3>New Clients</h3>
                <p>
                  Get discovered and booked by new clients anytime, anyplace all
                  from the comfort of your phone.
                </p>
              </div>
              <div className="salonist_why_single">
                <img src={money} alt="" />
                <h3>Make More Money</h3>
                <p>
                  With Plug handling getting new clients for your business, you
                  are able to focus on what really matters; Improving your
                  skills and services. <br /> One plus one = More money.
                </p>
              </div>
              <div className="salonist_why_single">
                <img src={commit} alt="" />
                <h3>No Commitment</h3>
                <p>
                  Register as stylist on our platform for FREE and close your
                  account whenever you wish.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="salonist_how_container">
          <div className="salonist_how">
            <h1>How Does Plug Work?</h1>
            <div className="salonist_how_steps">
              <div className="salonist_step">
                <img src={how1} alt="" />
                <h3>Step One</h3>
                <p>Upload images of the work you have done to the platform.</p>
              </div>
              <div className="salonist_step">
                <img src={how2} alt="" />
                <h3>Step Two</h3>
                <p>
                  A user uploads an image of a hairstyle she wants and simply
                  matches it to an image of a service you are offering.
                </p>
              </div>
              <div className="salonist_step">
                <img src={how3} alt="" />
                <h3>Step Three</h3>
                <p>Sit back, relax as you get appointment notifications.</p>
              </div>
              <div className="salonist_step">
                <img src={how4} alt="" />
                <h3>Step Four</h3>
                <p>Call and schedule appointments with clients.</p>
              </div>
              <div className="salonist_step">
                <img src={how5} alt="" />
                <h3>Step Five</h3>
                <p>Get positive reviews, get more bookings. Repeat.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="salonist_fee_container">
          <div className="salonist_fee">
            <div className="salonist_fee_text">
              <h2>A one time fee for a lifetime client</h2>
              <p>
                A 20% commission is paid to Plug for every appointment booked
                through plug.{" "}
              </p>
              <h3>How do you pay for it</h3>
              <ul>
                <li>
                  Client book an appointment by paying 20% deposit of the
                  service
                </li>
                <li>
                  Client completes their payment to YOU after the payment{" "}
                </li>
                <li>
                  The 20% deposit is the commission earned by Plug once you
                  complete the appointment.
                </li>
              </ul>
            </div>
            <div className="salonist_fee_image">
              <div>
                <img src={lady} alt="" className="lady" />
              </div>
              <img src={phone} alt="" className="phone" />
            </div>
          </div>
        </div>

        <div className="salonist_getStarted_container">
          <div className="salonist_getStarted">
            <h2>Get Started</h2>
            <div className="general">
              <h4>General</h4>
            </div>
            <div className="getStarted_steps">
              <div className="getStarted_step">
                <img src={download} alt="" />
                <h3>Download App</h3>
                <p>
                  You can download the app directly on an Android device through
                  Google Playstore.
                </p>
                <button>Download (COMING SOON)</button>
              </div>
              <div className="getStarted_step">
                <img src={register} alt="" />
                <h3>Register</h3>
                <p>
                  Fill the registration form provided in the app. You are
                  required to answer all the questions and upload photos of your
                  work to successfully submit your registration.
                </p>
              </div>
              <div className="getStarted_step">
                <img src={train} alt="" />
                <h3>Training and account activation</h3>
                <p>
                  Once you get notified about your qualification, you are
                  required to go through a short training on how to use the app
                  and activate your account.
                </p>
                <Link to="/apply">
                  <div className=" buttonDark">APPLY NOW</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
