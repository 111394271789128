import React, { Component } from 'react'
//import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Newsroom from './newsroom'
import ReactLoading from 'react-loading'
import moment from 'moment'



export default class ArticleOpen extends Component {
    constructor(props) {
        super(props)
        const article_id = props.match.params.article_id
        this.updateComments = this.updateComments.bind(this)
        this.postComment = this.postComment.bind(this)
        this.formatDate = this.formatDate.bind(this)
        this.state = {
            id: article_id,
            isLoaded: false,
            name: '',
            comment: '',
            commentsLoaded: false,
            name: '',
            email: '',
            message: '',
            submit: 'Post Comment'
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        axios.get(`https://www.plugblog.xyz//wp-json/wp/v2/articles/${this.state.id}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoaded: true
                })
            })
            .catch(err => console.log('error', err))
        console.log('data', this.state.id)

        this.updateComments()
        window.scrollTo(0, 0)

    }
    updateComments() {
        //everytime a comment is posted or on initial post load
        axios.get(`https://www.plugblog.xyz//wp-json/wp/v2/comments`)
            .then(res => {
                this.setState({
                    comments: res.data,
                    commentsLoaded: true
                })
            })
            .catch(err => console.log('error', err))

        console.log('comments loaded');

    }
    clearFields() {
        this.setState({
            email: '',
            submit: 'Post Comment',
            name: '',
            message: ''
        })
    }


    postComment(e) {
        //this will use content in state from a form to post a comment 
        e.preventDefault()
        let { email, id, name, message } = this.state
        this.setState({ submit: 'sending...' })
        axios.post('https://www.plugblog.xyz/wp-json/wp/v2/comments', {
            author_name: name,
            author_email: email === '' ? 'anon@plugblog.xyz' : email,
            post: id,
            content: message,
        })
            .then(function (response) {
                console.log('comment post successful', response);

            })
            .catch(function (error) {
                console.log(error);
            });
        setTimeout(() => {
            this.updateComments()
            this.clearFields()
        }, 2000);

        console.log('Comment Posted')

    }
    formatDate(date){
        let year  = date.substring(0,4)
        let month  = date.substring(5,7)
        let day  = date.substring(8,10)
        let newDate = moment(`${year}${month}${day}`, 'YYYYMMDD')
        return newDate.format('ll')
        
    }

    render() {
        const data = this.state.data ? this.state.data : null
        const comments = this.state.comments ? this.state.comments : null
        const { commentsLoaded } = this.state
        const id = this.props.match.params.article_id
        console.log('data in render', data);
        

        return (
            <div className="article_open_container">

                {data ? <div className="article_open">
                    <h3>{data.acf.category}</h3>
                    <h1>{data.title.rendered}</h1>
                    <h3 className = "article_date">{this.formatDate(data.date)} <span style = {{marginLeft: 90}}> BY MICHELLE OIGO</span></h3>
                    <div className="article_content" dangerouslySetInnerHTML={{ __html: data.content.rendered }}></div>
                </div>
                    :
                    <ReactLoading type={'bubbles'} color={'#E1A7C3'} height={'50px'} width={'50px'} />}
                <div className="seperator"></div>
                <div className="article_socials">
                    <div className="facebook_like"><div id="fblike"></div><div>LIKE</div></div>
                    <div className="tweet"><div id="tweet"></div><div>TWEET</div></div>
                </div>
                <div className="comments_container">
                    <div className="comments">
                        <h2>{commentsLoaded ? comments.filter(item => item.post === parseInt(id, 10)).length : null} Comments</h2>
                        {commentsLoaded ? comments.filter(item => item.post === parseInt(id, 10)).map(
                            comment => <div className='comment' key={comment.id}>
                                <div className="comment_author">
                                    {comment.author_name}
                                </div>
                                <div className="comment_date">
                                    {this.formatDate(comment.date)}
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: comment.content.rendered }} className="comment_body">
                                    

                                </div>
                            </div>
                        ) : <ReactLoading type={'bubbles'} color={'#E1A7C3'} height={'50px'} width={'50px'} />}
                    </div>
                    <h1>Leave a Comment</h1>
                    <form onSubmit={this.postComment} action="submit">

                        <textarea style={{ paddingTop: '0px' }} id="message" value={this.state.message} onChange={(e) => { this.setState({ message: e.target.value }) }} type="text" placeholder="Message" />
                        <input value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }) }} placeholder="Name" type="text" required />
                        <input value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }} type="email" placeholder="Email Address" />
                        <button type="submit" className="button">{this.state.submit}</button>
                    </form>
                </div>
            </div>
        )
    }
}
