import React, { Component } from 'react'

export default class Policy extends Component {
    componentDidMount(){
        window.scrollTo(0,0)
    }
    render() {
        return (
            <div className='about_container'>
                <h2>1. WHO WE ARE</h2>

                <p>Edyrene Limited is a private limited company incorporated under the laws of the Republic of Kenya, of P. O Box 29-00517, Uhuru Gardens, Nairobi, including all assigns and successors (<strong>“the Company”, “we” or “us”</strong>) and is the registered owner of Plug App and Plug Partner’s App (collectively, <strong>“the Service or “the App”</strong>) We are the data controller responsible for your personal information when you subscribe to, access and use the Service.</p>
                <p>The Policy applies to all visitors, users, and others who access the Service (<strong>"Users"</strong>). It is how we and some of the companies we work with collect, use, share and protect information in relation to the Service and your choices about the collection and use of your information.</p>
                <p>By using the Service, you understand and agree that we are providing a platform for you to post content, including photos, comments and links (<strong>"User Content"</strong>), to the Service and to share User Content publicly. This means that other Users may search for, see, use, or share any of your User Content that you make publicly available through the Service, consistent with the terms and conditions of this Privacy Policy and our Terms of Use.</p>
                <p>We reserve the right to modify and update this policy at any given time, of which you will be promptly notified. We welcome your questions and comments on this policy.</p>


                <h2>2. YOUR CONSENT</h2>

                <ol>
                    <li>By downloading, installing and registering for the Service, you grant us express, unequivocal, free, specific and informed consent and allow us to process your personal data relating to the Service, including access to your gallery and phone book  in your device.</li>
                    <li>You are obliged to revoke your consent at any time by getting in touch with us. However, you will no longer have access to the Service.</li>
                    <li>You acknowledge that there will be exceptional circumstances where personal data can be processed without the data subjects consent. There may be limitations on data subject rights when required by the law or when there are competing rights, therefore it will require an assessment based on specific facts and circumstances.</li>
                </ol>


                <h2>3. INFORMATION WE COLLECT</h2>

                <ol>
                    <li>
                        <p><strong>Personal identification information including, but not limited to:</strong></p>
                        <ol style={{ listStyle: 'lower-roman' }}>
                            <li>Your official names, username, password, phone number, gender and e-mail address, including your web request, browser type, referring / exit pages and URLs, number of clicks and how you interact with links on the Service, domain names, landing pages, pages viewed, and other such information. We may also collect similar information from emails sent to you which then help us track which emails are opened and which links are clicked by recipients. </li>
                            <li>Your user content (e.g. photos, links, comments,) profile picture and information that you post on the Service and any information availed to the public;</li>
                            <li>Contents saved and stored in your mobile device gallery and contacts listed in your phone book;</li>
                            <li>Communications between you and us. For example, your contact information that we may use for Customer Support. We may send you Service-related emails (e.g account verification, updates to features of the Service, technical and security notices). Kindly note that you may not opt out of Service-related emails.</li>
                            <li>If you decide to delete your account and the App from your device, be advised we reserve the right to retain and archive the data and information you avail to us, especially your phone number and payment records, at our discretion to the extent permitted in law.</li>
                        </ol>
                    </li>
                    <li>
                        <p><strong>Connecting with other Users on the Service:</strong></p>
                        <ol style={{ listStyle: 'lower-roman' }} >
                            <li>If you choose to connect with other Users through a search of names or usernames on the App then simply type a name and we will perform a search.</li>
                            <li>If you choose to invite someone to the Service, you may select a person directly from the contacts list on your device and send a text or email from your personal account. You understand and agree that you are responsible for any charges that apply to communications sent from your device, and because this invitation is coming directly from your personal account with your assigned referral code, we only have access to monitor the exchange. We do not control this communication.</li>
                        </ol>
                    </li>
                    <li>
                        <p><strong>Third Party Information:</strong></p>
                        <ol style={{ listStyle: 'lower-roman' }} >
                            <li>We work with third-party service providers to help us operate, provide, improve, understand, customize, support, and market the Service. For example, we work with companies to distribute our Service, provide our infrastructure, delivery, and other systems, process payments, help us understand how people use the Service, market our Service, help you connect with businesses using the Service, conduct surveys and research for us. These companies may provide us with information about you in certain circumstances; for example, app stores may provide ratings and reports to help us diagnose and improve the Service.</li>
                            <li>We use third-party analytics tools to help us measure traffic and usage trends for the Service. These tools collect information sent by your device through the Service, including the web pages you visit, add-ons, and other information that assists us in improving the Service.</li>
                        </ol>
                    </li>
                    <li>
                        <p><strong>Device Identification and Access:</strong></p>
                        <ol style={{ listStyle: 'lower-roman' }} >
                            <li>In addition to log data, we collect information about the device you are using to access the Service, including the type of device, operating system, settings, unique device identifiers and crash data that helps us understand when something breaks.</li>
                            <li>
                                <p>You acknowledge and grant us consent to access your device gallery and phone book  information to:</p>
                                <ul>
                                    <li>facilitate easier access to images in your gallery that you wish to post and share while using the App; and</li>
                                    <li>facilitate easier access to your contact list when the user wishes to invite their contacts and add them as friends in the App.</li>
                                </ul>
                            </li>
                            <li>You may revoke this consent at any time. However, your use and access to the Service will be severely limited.</li>
                        </ol>
                    </li>
                    <li>
                        <p><strong>Metadata:</strong></p>
                        <ol style={{ listStyle: 'lower-roman' }} >
                            <li>Metadata is usually technical data that is associated with your content. For example, Metadata can describe how, when and by whom a piece of your content was collected and how that content is formatted.</li>
                            <li>You can add or may have Metadata added to your content including a hashtag (e.g. to mark keywords when you post a photo), geotag (e.g., to mark your location to a photo), comments or other data. This makes your content more visible  and interactive to other Users.</li>
                        </ol>
                    </li>
                </ol>


                <h2>4. WHAT WE DO WITH YOUR INFORMATION</h2>

                <p>In addition to some of the specific uses of information we describe in this Privacy Policy, we may use information that we receive to:</p>
                <ol>
                    <li>
                        <p>Provide and improve our Services by:</p>
                        <ol>
                            <li>helping you efficiently access your information after you register and sign in;</li>
                            <li>keeping track of your information and updates so you will not have to re-enter it when you access the Service;</li>
                            <li>recommending certain content in accordance with your interests and inferences and provide personalized content and information to you and others, which could include various forms of marketing;</li>
                            <li>providing updates, improve, test, monitor the effectiveness of our Service and develop new products and features;</li>
                            <li>monitoring metrics such as total number of visitors, traffic, and demographic patterns;</li>
                            <li>diagnose or fix technical problems and automatically update the Service on your device with feedback you provide;</li>
                            <li>running contests, special offers or other events ("Campaign") on the Service. If you do not want to participate in a Campaign do not use the particular Metadata (i.e. hashtag or geotag) associated with that Campaign.</li>
                        </ol>
                    </li>
                    <li>
                        <p>Enhance effective communications by:</p>
                        <ol>
                            <li>using your email address to respond to your inquiries, questions, and other requests. If you decide to opt-in to our mailing list, you will receive emails that may include newsletters, updates and related information. If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email;</li>
                            <li>sharing your contact information when scheduling appointments between Users and Service Providers, making order payments and delivery notifications;</li>
                            <li>sending your information with parties you choose to communicate with.</li>
                        </ol>
                    </li>
                    <li>
                        <p>Ensure safety and Security by:</p>
                        <ol>
                            <li>verifying accounts and activities on and off our Service, through investigation of suspicious activities and monitoring compliance with our Terms of Use and Privacy Policy.</li>
                        </ol>
                    </li>
                    <li>
                        <p>Share with third-party service providers who help us operate, provide, improve, understand, customize, support, and market the Service. When we share information with third-party service providers and in this capacity, we require them to use your information on our behalf in accordance with our instructions, the Terms of Use and Privacy Policy.</p>
                    </li>
                </ol>


                <h2>5. SHARING OF YOUR INFORMATION</h2>

                <ol>
                    <li>
                        <p>We do not sell, trade, or rent your personal identification information to others (or the group of companies of which the Service is a part of) without your consent, except as noted in this Policy.</p>
                    </li>
                    <li>
                        <p>When responding to legal requests and preventing harm:</p>
                        <ol>
                            <li>We may access, preserve and share information when we have a good faith and belief it is necessary to: detect, prevent and address fraud and other illegal activity; to protect ourselves, you and others, including as part of investigations; and to prevent death or imminent bodily harm. Information we receive about you may be accessed, processed and retained for an extended period of time when it is the subject of a legal request or obligation, governmental investigation, or investigations concerning possible violations of our terms or policies, or otherwise to prevent harm.</li>
                            <li>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by The Data Protection Act Kenya, 2019, and any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction. This may include responding to legal requests from jurisdictions outside of the Republic of Kenya.</li>
                        </ol>
                    </li>
                    <li>
                        <p>Third-Party Service Providers:</p>
                        <ol>
                            <li>We may share your information with third parties that perform services on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance, in which case we will require those third parties to honor this Privacy Policy.</li>
                            <li>We may share your information and content with businesses that are legally part of the same group of companies that the Service is part of, or that become part of that group ("Affiliates"). Affiliates may use this information to help provide, understand, and improve the Service (including by providing analytics) and Affiliates' own services (including by providing you with better and more relevant experiences) in line with this Privacy Policy.</li>
                            <li>We also may share your information with third-party organizations that help us provide the Service to you ("Service Providers"). Our Service Providers will be given access to your information as is reasonably necessary to provide the Service under reasonable confidentiality terms.</li>
                            <li>We may share your information with advertisers and investors for the purpose of conducting general business analysis.</li>
                        </ol>
                    </li>
                    <li>
                        <p>Marketing Communications:</p>
                        <ol>
                            <li>With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.</li>
                        </ol>
                    </li>
                    <li>
                        <p>Interactions with Other Users:</p>
                        <ol>
                            <li>If you interact with other users of the Service, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, liking, sharing and saving images and pictures posted by other users.</li>
                        </ol>
                    </li>
                    <li>
                        <p>Online Postings:</p>
                        <ol>
                            <li>When you post comments and reviews of appointments on the Service, your posts may be viewed by all users and may be publicly distributed outside the Service in perpetuity.</li>
                        </ol>
                    </li>
                    <li>
                        <p>Sale, Merger, Acquisition or Bankruptcy:</p>
                        <ol>
                            <li>If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity. If we go out of business or enter bankruptcy, your data and information shall be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that the transferee may decline to honor commitments we made in this Privacy Policy.</li>
                            <li>We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly.</li>
                        </ol>
                    </li>
                </ol>

                <h2>HOW WE STORE YOUR INFORMATION</h2>

                <ol>
                    <li>Your information collected through the Service may be stored and processed in the Republic of Kenya or any other country in which the Service, its Affiliates or Service Providers maintain facilities, in accordance with the Data Protection Act Kenya, 2019, and the General Data Protection Regulations, and may not be equivalent to those of your home country.</li>
                    <li>All information you provide to us is stored on our secure servers. We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</li>
                    <li>We use commercially reasonable safeguards to help keep the information collected through the Service secure and take reasonable steps (such as requesting a unique and secret password) to verify your identity before granting you access to your account.</li>
                    <li>Please do your part to help us. You are responsible for maintaining the secrecy of your unique password and account information, and for controlling access to emails between you and the Service, at all times. We are not responsible for the functionality, privacy, or security measures of any other organization.</li>
                </ol>


                <h2>8. YOUR CHOICES ABOUT YOUR INFORMATION</h2>

                <ol>
                    <li>
                        <p>Account Information:</p>
                        <p>You may at any time review or modify the information in your account through:</p>
                        <ol>
                            <li>Logging into your account settings and updating your account;</li>
                            <li>Contacting us using the contact information provided below;</li>
                            <li>The browser you use lets you control cookies or other types of local data storage.</li>
                            <li>Your mobile device lets you choose how and whether your precise location, photos, contacts and other data is shared with us.</li>
                        </ol>
                    </li>
                    <li>
                        <p>Deleted information:</p>
                        <p>Upon your request to terminate your account, we will deactivate your account from the Service. However, we reserve the right to retain and archive the data and information you provided, especially phone number and payment records, at our sole discretion, to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use or comply with legal requirements.</p>
                    </li>
                    <li>
                        <p>Emails and Communications:</p>
                        <p>If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:</p>
                        <ol>
                            <li>Noting your preferences at the time you register your account with the Application</li>
                            <li>Logging into your account settings and updating your preferences.</li>
                            <li>Contacting us using the contact information provided below.</li>
                        </ol>
                        <p>If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.</p>
                    </li>
                </ol>


                <h2>9. HOW YOU EXERCISE YOUR RIGHTS</h2>

                <p>Your data is protected under the Data Protection Act Kenya 2019, in line with the General Data Protection Regulations.</p>
                <ol>
                    <li>
                        <p>You have a right to:</p>
                        <ol>
                            <li>to be informed of the use to which their personal data is to be put;</li>
                            <li>to access their personal data in custody of data controller or data processor;</li>
                            <li>to object to the processing of all or part of their personal data;</li>
                            <li>to correction of false or misleading data; and</li>
                            <li>to deletion of false or misleading data about you.</li>
                        </ol>
                    </li>
                    <li>
                        <ol>
                            <p>We collect, use and share the information we have as described above:</p>
                            <li>as necessary to fulfill our Terms;</li>
                            <li>consistent with your consent, which you can revoke at any time;</li>
                            <li>as necessary to comply with our legal obligations;</li>
                            <li>occasionally to protect your vital interests, or those of others;</li>
                            <li>as necessary in the public interest; and</li>
                            <li>as necessary for our (or others’) legitimate interests, including our interests in providing an innovative, relevant, safe, and profitable Service to our users and partners, unless those interests are overridden by your interests or fundamental rights and freedoms that require protection of personal data.</li>
                        </ol>
                    </li>
                </ol>


                <h2>10. CROSS BORDER TRANSFER OF DATA</h2>

                <ol>
                    <li>
                        <p>In line with the Data Protection Act Kenya, 2019, we reserve the right to transfer your personal data to another country when it is necessary:</p>
                        <ol>
                            <li>for the performance of a contract between you and the data controller or data processor or implementation of pre-contractual measures taken at your request;</li>
                            <li>for the performance of a contract concluded in your interest between the data controller and another person;</li>
                            <li>for any matter of public interest;</li>
                            <li>for the establishment, exercise or defence of a legal claim;</li>
                            <li>in order to protect your vital interests or of other persons, where you are physically or legally incapable of giving consent; or</li>
                            <li>for the purpose of compelling legitimate interests pursued by the data controller or data processor which are not overridden by your interests, rights and freedoms.</li>
                        </ol>
                    </li>
                    <li>
                        <p>The processing of sensitive personal data out of Kenya shall only be effected upon obtaining your consent and on obtaining confirmation of appropriate safeguards.</p>
                    </li>
                </ol>


                <h2>11. CHILDREN'S PRIVACY</h2>

                <p>The Service does not knowingly collect or solicit any information from anyone under the age of majority (18+) or knowingly allow such persons to register for the Service. The Service and its content are not directed at children under the age of majority (18+). In the event that we learn that we have collected personal information from a child, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child, please contact us through the information provided below. </p>


                <h2>12. OTHER WEB SITES AND SERVICES</h2>

                <p>We are not responsible for the practices employed by any websites or services linked to or from our Service, including the information or content contained within them. Please remember that when you use a link to go from our Service to another website or service, our Privacy Policy does not apply to those third-party websites or services. Your browsing and interaction on any third-party website or service, including those that have a link on our website, are subject to that third party's own rules and policies. In addition, if you are using a third-party website or service and you allow them to access your content you do so at your own risk.</p>


                <h2>13. INTELLECTUAL PROPERTY POLICY</h2>

                <p>
                We are committed to helping people and organizations protect their intellectual property rights. Our users agree to our Terms of Use by installing, accessing, or using our apps, services, features, software, or website (together, “Services”). Our Terms do not allow our users to violate someone else’s intellectual property rights when using our Services, including their copyrights and trademarks.
                </p>
                <p>
                As explained in more detail in our Terms of Use, we do not have a right over nor own the User’s contents (i.e photos, posts, comments or information) in the ordinary course of providing our Services. We do, however, host the User’s account information, including the User’s profile picture, profile name, and posts, if they decide to include them as part of their account information.  
                </p>


                <h2>14. HOW TO CONTACT US</h2>

                <p>If you have any questions or complaints about this Privacy Policy or the Service, please contact us through:</p>
                <ul>
                    <li>Company Name:	Edyrene Limited</li>
                    <li>Postal Address:	P.O Box 29-00517, Uhuru Gardens</li>
                    <li>City:		    Nairobi, Kenya</li>
                    <li>Email Address:	plugltd@gmail.com</li>
                </ul>


                <h2>15. CHANGES TO OUR PRIVACY POLICY</h2>

                <p>It may be necessary for the Service to modify or update this Privacy Policy from time to time, for example to reflect updates to the Service or where required by law. We will notify you before we make changes to this Privacy Policy and give you the opportunity to review the revised Privacy Policy before continuing to use the Service. Your continued use after any modification to this Privacy Policy will constitute your acceptance of such modification.</p>


            </div>
        )
    }
}
