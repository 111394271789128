import React, { Component } from "react";
import Article from "./article";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";

export default class Newsroom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      isLoaded: false,
      media: [],
    };
  }
  componentDidMount() {
    const articles = axios.get(
      "https://www.plugblog.xyz///wp-json/wp/v2/articles/"
    );

    const media = axios.get("https://www.plugblog.xyz///wp-json/wp/v2/media/");

    Promise.all([articles, media])
      .then((res) =>
        this.setState({
          articles: res[0].data,
          media: res[1].data,
          isLoaded: true,
        })
      )
      .catch((err) => console.log(err));

    console.log(this.state.aricles);
  }
  render() {
    const { articles, isLoaded, media } = this.state;
    console.log("media", media);
    console.log(
      "media filtered",
      media.filter((id) => id.id < 10)
    );
    return (
      <div className="newsroom_container">
        {isLoaded ? (
          <div style={{ margin: "auto" }}>
            <ReactLoading
              type={"bubbles"}
              color={"#E1A7C3"}
              height={"50px"}
              width={"50px"}
            />
          </div>
        ) : (
          <OwlCarousel
            className="newsroom_container"
            loop
            margin={5}
            nav={false}
            dots={true}
            autoWidth={true}
            center={true}
            autoPlay={true}
            animateOut="fadeOut"
          >
            <Article
              img="https://images.unsplash.com/photo-1523303821445-f2983744a23e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80"
              category="hair guide"
              title="Why Your Curls are not Popping"
            />
            <Article
              img="https://images.unsplash.com/photo-1548158402-114671b19fab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=333&q=80"
              category="hair guide"
              title="Orange Juice is Good for Hair?"
            />
            <Article
              img="https://images.unsplash.com/photo-1570915226741-cc7d678ad7ce?ixlib=rb-1.2.1&auto=format&fit=crop&w=1569&q=80"
              category="hair guide"
              title="Your Dreadlocks Desever Better"
            />
            {
              // articles.map((article) => (
              //   <div
              //     prop1="hello"
              //     key={article.id} /* to={`/articleopen/${article.id}`} */
              //   >
              //     <Article
              //       category={article.acf.category}
              //       title={article.title.rendered}
              //       key={article.id}
              //       media={article.featured_media}
              //       img={media.filter((id) => id.id === article.featured_media)}
              //     />
              //   </div>
              // ))
            }
          </OwlCarousel>
        )}

        {/* <div className="wpArticles">
                    {articles.map(article =>
                        <Link to={`/articleopen/${article.id}`}><Article
                            category={article.acf.category}
                            title={article.title.rendered}
                            key={article.id}
                            media={article.featured_media}

                        />
                        </Link>

                    )}
                </div> */}
      </div>
    );
  }
}
