import React, { Component } from "react";
import { Link } from "react-router-dom";
import edy_logo from "../img/EDR.png";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }
  render() {
    return (
      <div className="footer_container">
        <div className="footer">
          <div className="terms">
            {" "}
            <Link to="/terms"> Terms of use</Link>/{" "}
            <Link to="/policy"> Privacy policy</Link>
          </div>
          <div className="location">
            <h1>Location</h1>
            <h3>Nairobi, Kenya</h3>
            <div className="num">
              <i className="fas fa-phone"></i>
              <h3>
                <a
                  style={{
                    textDecoration: "none",
                    color: "#B05A83",
                  }}
                  href="tel:+254742942700"
                >
                  0742 942700
                </a>
              </h3>
            </div>
            <div className="mail">
              <i className="fas fa-envelope"></i>
              <h3>
                <a
                  style={{
                    color: "#B05A83",
                  }}
                  href="mailto:info@plugltd.com"
                >
                  info@plugltd.com
                </a>
              </h3>
            </div>
            <Link to="/salonist">
              <div className="salonist button">Apply as a Stylist</div>
            </Link>
          </div>
          <Link to="/contact">
            <div className="get_intouch button">
              Get in Touch
              {/* <h1>Join Our Newsletter</h1>
                        <form onSubmit={console.log('submitted')} className="sub_input">
                            <input  value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }} type="email" className="form-control" id="book_email" aria-describedby="emailHelp" placeholder="example@example.com" required />
                            <button style = {{fontWeight: 300, fontFamily: 'inherit'}} type="submit" className="button ">subscribe</button>
                        </form> */}
            </div>
          </Link>
        </div>
        <div
          /* style={{
                    height: '1px',
                    width: '85%',
                    backgroundColor: 'rgba(176, 90, 131, 0.1)',
                    position: 'relative',
                    top: '20px'
                }} */ className="hr"
        ></div>
        <div className="copyright">
          <h3>Copyright © 2020 EDYRENE LIMITED. All rights reserved.</h3>
          <a href="javascript:void(0)">
            <img src={edy_logo} alt="" />
          </a>
        </div>
      </div>
    );
  }
}
