import React, { Component } from "react";
import logo from "../img/plug_logo.jpg";
import { Link } from "react-router-dom";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.nav_response = this.nav_response.bind(this);
    this.navRef = React.createRef();
    this.state = {
      navTop: 0,
      link_style: {
        position: "",
      },
    };
  }
  componentDidMount() {
    this.setState({
      navTop: this.navRef.current.offsetTop,
    });
    window.addEventListener("scroll", this.nav_response);
  }
  nav_response() {
    let scroll = window.scrollY;
    this.setState({
      link_style:
        scroll > this.state.navTop
          ? {
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              borderTop: "none",
              zIndex: 100,
            }
          : {
              position: "relative",
            },
    });
  }

  render() {
    return (
      <div className="header_container">
        <div className="socials_container">
          <div className="socials">
            <a href="https://www.facebook.com/plugltd.kenya" target="blank">
              <div className="facebook"></div>
            </a>
            <a
              href="https://www.instagram.com/plug.kenya/?hl=en"
              target="blank"
            >
              <div className="instagram"></div>
            </a>
            {/* <a href="#logo"><div className='twitter' ></div></a> */}
          </div>
          <Link to="/salonist">
            <div className="salonist button">Stylist</div>
          </Link>
        </div>

        <div className="logo" id="logo">
          <img src={logo} alt="" />
        </div>
        <nav ref={this.navRef} style={this.state.link_style}>
          <div className="links_container">
            <Link to="/">HOME</Link>
            <Link to="/about">ABOUT</Link>
            <Link to="/features">FEATURES</Link>
            <Link to="/faqs">FAQs</Link>
          </div>
        </nav>
      </div>
    );
  }
}
