import React, { Component } from 'react'
import {Helmet} from 'react-helmet'

export default class Contact extends Component {
    constructor(props){
        super(props)
        //functuion declaration
        this.sendMessage = this.sendMessage.bind(this)
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            submit: 'Submit'
        }
    }
    componentDidMount(){
        window.scrollTo(0,0)
    }
    clearFields() {
        this.setState({
            email: '',
            phone: '',
            submit: 'Submit',
            name: '',
            message: ''
        })
    }

    sendMessage(e){
        e.preventDefault()

        this.setState({submit: 'sending...'})
        setTimeout(() => {
           this.clearFields() 
           this.setState({submit: 'Submit'})
        }, 2000);

    
        console.log('message sent')
        
    }
    render() {
        return (
            <div className = "contact_container">
                <Helmet>
                    <title>Get in Touch</title>
                    <meta 
                        name='description'
                        content = 'Reach out to us for any questions'
                    />
                </Helmet>
                <div className="contact">
                    <h1>Get in Touch</h1>
                    <form onSubmit = {this.sendMessage} action="submit">
                        <div className="namephone">
                            <input value = {this.state.name} onChange = {(e)=>{this.setState({name: e.target.value})}} placeholder = "Name" type="text" required/>
                            <input value = {this.state.phone} onChange = {(e)=>{this.setState({phone: e.target.value})}} placeholder = "Number (e.g 0712345678)" type="tel" pattern="[0-9]{4}[0-9]{6}" required/>
                        </div>
                        <input value = {this.state.email} onChange = {(e)=>{this.setState({email: e.target.value})}} type="email" placeholder = "Email Address"/>
                        <input id  = "message" value = {this.state.message} onChange = {(e)=>{this.setState({message: e.target.value})}} type="text" placeholder = "Message" />
                        <button type = "submit" className="button">{this.state.submit}</button>
                    </form>
                </div>
                
            </div>
        )
    }
}
