import React, { Component } from 'react'

export default class Terms extends Component {
    componentDidMount(){
        window.scrollTo(0,0)
    }
    render() {
        return (
            <div className="about_container">
                <h1>Terms & Conditions of PLUG</h1>
                <h2>Effective as of 25th April 2020</h2>
                <p>
                    Welcome and thank you for using Plug App (<strong>“the Service” or “the App”</strong>), a digital platform dedicated to helping you discover, share and achieve your beauty inspirations by connecting you to competent beauty service providers.
                </p>
                <p>
                    You may upload, post and share pictures, comments and links of beauty services (<strong>“User Content”</strong>), which we may store, distribute and share with other users in accordance with the Agreement and the Privacy Policy.
                    </p>
                <p>
                    This document constitutes the entire agreement between you and the Company, pertaining to your use of the Service (<strong>“the Agreement”</strong>). Prior agreements between you and the Company are void. Please read it carefully. Installing, accessing or using the Service amounts to unconditional acceptance of the terms. If you do not agree, provide false information or breach any of the terms stated, you are not allowed to use the Service.
                </p>
                <p>
                    Edyrene Limited is a private limited company incorporated under the laws of the Republic of Kenya, of P.O. Box 29-00517, Uhuru Gardens, Nairobi, including all assigns and successors, and is the registered owner of the Service (<strong> “the Company”, “Plug”, “we” or “us”</strong>).
                </p>


                <h2>1. OUR SERVICE</h2>
                <p>
                    Plug helps you discover different beauty interests and express the look you love by connecting you to various beauty service providers (<strong>“Service Providers”</strong>). It enables you to arrange and schedule appointments with Service Providers depending on the beauty services you are interested in.

                </p>


                <h2>2. ACCESS TO AND GENERAL USE OF THE SERVICE</h2>
                <ol>
                    <li>
                        <p> Who can access the Service:</p>
                        <p>
                            YOU MUST BE ABOVE 18 YEARS (OR AGE OF MAJORITY IN YOUR JURISDICTION) TO ACCESS THE SERVICE AND CAN FORM LEGALLY BINDING CONTRACTS UNDER APPLICABLE LAW.
                            </p>

                    </li>
                    <li>
                        <p>
                            We support the values of tolerance and respect of others. For this reason, by using the Service, you agree not to:
                            </p>
                        <ul>
                            <li>
                                Use the Service other than for its intended purpose nor conduct illegal activities that may infringe anyone’s rights.
                                </li>
                            <li>
                                Post any unlawful, false, misleading, threatening, abusive, defamatory, obscene, vulgar, pornographic, or indecent information; that promotes racism, bigotry, hatred or physical harm against a group or individual.
                                </li>
                            <li>
                                Post Content which contains destructive software such as a virus, worm, Trojan horse, time bomb, bot, or other harmful components designed to interrupt, destroy or limit the functionality of computer software, hardware or telecommunications equipment.
                                </li>
                            <li>
                                Harvest, collect or store information in unauthorized ways, including in an automated way without our express permission, (including personally identifiable information) about other users of the Service;
                                </li>
                            <li>
                                Restrict or inhibit other users from using and enjoying the Service, interfere with the proper workings of the Service, nor do anything which in our sole discretion imposes an unreasonable load on it;
                                </li>
                            <li>
                                Use the Service for commercial purposes without our prior written consent.
                                </li>
                        </ul>
                    </li>
                    <li>The Service shall be delivered solely via electronic download from Apple Store or Android Store. You will be required to register certain personal information, such as your name, email and physical address, mobile phone number and age, as well as at least one valid payment method (either a credit card or mobile money payment account) in order to register an account and sign in.</li>
                    <li>You agree to maintain accurate, complete, and up-to-date information in your account including a valid mobile money payment account, failure to which may result in your inability to access and use the Services or termination of your account.</li>
                    <li>By creating an account, you agree that the Services may send you text (SMS) messages or contact you through the phone number provided as part of the normal business operation of your use of the Services.</li>
                    <li>It is your responsibility to create a strong password and keep it confidential. You agree that you are entirely responsible for all activities that occur under your account, whether or not you have authorized them.</li>
                    <li>The installation and use of the Service require a compatible device with an Internet connection. You shall bear any further expenses for accessing and using the Internet, as well as any telecommunication expenses incurred in connection with the downloading, installation of and access to the Service.</li>
                </ol>


                <h2>3. LICENSE</h2>
                <ol>
                    <li>We grant you  a non-exclusive, revocable and non-transferable license to use the Service, unlimited in time. The territory in which the Service user license is granted shall be WORLDWIDE.</li>
                    <li>
                        
                            By posting, emailing or uploading Content to the Service, you grant us a royalty-free, transferable, perpetual, non-exclusive, irrevocable, unrestricted, worldwide license  and allow us to:
                        
                        <ul>
                            <li>Use, reproduce, store, adapt, translate, modify, create derivative works from, transmit, distribute, share, publicly perform or display such Content in relation to the Service;</li>
                            <li>To sub-license to Service Providers and third parties;</li>
                        </ul>
                    </li>
                    <li>You acknowledge and agree that we are not responsible for any loss, damage, or corruption that may occur to your Content.</li>
                    <li>You further acknowledge and agree that any Content you display on the Service will be considered non-confidential.</li>
                    <li>You agree that we can download and install updates to the App in your device.</li>
                    <li>You can end this license anytime by deleting your content or account. However, Content will continue to appear if you uploaded or shared it with others and they have not deleted it. Furthermore, we reserve the right to retain data and information you provided to us.  To learn more about how we use information, and how to control or delete your content, review the Privacy Policy.</li>
                </ol>


                <h2>4. INTELLECTUAL PROPERTY</h2>

                <ol>
                    <li>All materials displayed or accessible through the Service, including articles, text, photographs, images, icons, designs, illustrations, audio clips, video clips, computer software and codes (collectively, the <strong>"Company Content"</strong>) are protected under the laws of the Republic of Kenya and international treaties, international copyrights, trade secrets, patents, trademarks and other applicable intellectual property laws.</li>
                    <li>All products, services, brands, company names and logos used on the Service or related services are the trademarks of their respective owners. Any use of any of the marks appearing without the express written consent of the Company or the owner of the mark  is strictly prohibited.</li>
                    <li>While certain trademarks of third parties may be used by the Company under license, the display of third-party trademarks on the Service should not be taken to imply any relationship or license between us and the owner of said trademark.</li>
                </ol>


                <h2>5. YOUR CONTENT AND FEEDBACK</h2>

                <ol>
                    <li>Plug is a digital platform that allows you to upload, post and share beauty content, including pictures,comments and links. You retain all rights to the content and remain solely responsible.</li>
                    <li>Any disclosure of personally identifiable information shall be in accordance with our Privacy Policy.</li>
                    <li>We have the right, in our sole discretion, to review, edit, remove or refuse to post any Content for any reason, whether or not the Content is in violation of the Agreement.</li>
                    <li>Content posted on the App belongs to the respective owner, who remains solely responsible. We claim no right nor responsibility over any content posted on the platform.</li>
                    <li>Following termination or deactivation of your account, or if you remove any User Content from the Service, we may keep your User Content for a reasonable period of time for backup, archival, or audit purposes. We, and other users may retain and continue to use, store, display, reproduce, modify, create derivative works, perform, and distribute any of your User Content that other users have stored or shared on the App.</li>
                    <li>We value your feedback and input to help us improve our services. If you choose to submit comments, ideas or feedback, you agree that we are free to use them without any restriction or compensation to you. By accepting your submission, we do not waive any rights to use similar or related feedback previously known to us, or developed by our employees, or obtained from sources other than you.</li>
                </ol>


                <h2>6. THIRD PARTY SITES AND SERVICES</h2>

                <p>
                    The Service may contain links to third party services or activities that are not owned or controlled by us. Access to said sites is at your own risk and we will not be held responsible for any loss or damages incurred.
                </p>


                <h2>7. PAYMENT</h2>

                <ol>
                    <li>You acknowledge that use of the Service may result in expenses for the beauty services you receive from a Service Provider (<strong>“Charges”</strong>). 20% of the Charges is owed and paid to Plug (<strong>“the Deposit”</strong>) prior to scheduling an appointment with the Service Provider.</li>
                    <li>The Deposit is paid through a mobile money payment transaction using the in-app payment method provided in the App. We reserve the right not to schedule your appointment with the Service Provider if you fail to pay the Deposit.</li>
                    <li>You are required to pay the balance of the Charges to the Service Provider upon completion of the beauty services offered. Charges are detailed in the App and are standardized according to the current market rates for the beauty services, and will be inclusive of applicable taxes where required by law.</li>
                    <li>We reserve the right to act as the Service Provider’s limited commercial agent, solely for the purpose of collecting the Deposit, which acts as commission owed to Plug. Payment obligation you make via the In-app Payment shall be considered fulfilled as of the time that the payment has been received.</li>
                    <li>We will respond accordingly to any request from a Service Provider to modify the Charges for a particular beauty service provided.</li>
                    <li>You may elect to cancel your request for beauty services from a Service Provider 24 hours prior to the scheduled appointment, in which case you will be required to forfeit the Deposit paid.</li>
                    <li>All Charges are due immediately after which we will send you a receipt in the App. You agree that we may use a secondary payment method in your account, if available, if the primary account is invalid or expired.</li>
                    <li>We reserve the right to establish, remove or revise Charges for beauty services obtained through the use of the App at any time in our sole discretion. We shall use reasonable efforts to inform you of Charges that may apply, provided that you are responsible for Charges incurred under your account, regardless of your awareness of such Charges or the amounts thereof.</li>
                    <li>We may occasionally provide you with promotional offers and discounts that may result in different amounts charged on the Deposit for similar beauty services obtained through the use of the Service, and you agree that such promotional offers and discounts shall have no bearing on the Charges applied to you.</li>
                    <li>This payment structure is intended to fully compensate the Service Provider for the beauty services offered. Gratuities are voluntary and you are under no obligation to provide additional payments.</li>
                    <li>After you have received beauty services ordered through the Service, you will have the opportunity to rate your experience and leave additional feedback about your Service Provider.</li>
                </ol>


                <h2>8. COPYRIGHT INFRINGEMENT/ COMPLAINTS</h2>

                <p>
                    Plug is under the jurisdiction of the copyright laws of the Republic of Kenya and abides by international copyright and patent laws. In case you wish to report a copyright infringement or file a complaint, kindly send us an email on   plugltd@gmail.com.
                </p>


                <h2>9. LIMITATION OF LIABILITY</h2>

                <p>
                    IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO, ANY DIRECT, INDIRECT, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES OR ANY DAMAGES FOR LOSS OF USE, PRODUCTION, INCOME OR PROFITS (ANTICIPATED OR OTHERWISE), SAVINGS OR GOODWILL, ECONOMIC LOSS, OR OTHER INTANGIBLE LOSS, WHETHER IN CONTRACT (INCLUDING FUNDAMENTAL BREACH), TORT (INCLUDING NEGLIGENCE IN ANY DEGREE OR MISREPRESENTATION) OR UNDER ANY OTHER THEORY OF LAW OR EQUITY, RELATING TO THE SERVICE, EVEN IF THE COMPANY HAS BEEN ADVISED OR OUGHT TO HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>


                <h2>10. WARRANTY</h2>

                <p>
                    TO THE FULLEST EXTENT PERMITTED BY LAW, THE COMPANY EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, RELATING TO THE SERVICE, PERFECT CONTENT AND USER CONTENT, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE, TO THE EXTENT PERMITTED BY LAW.
                </p>
                <p>
                    NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE COMPANY, WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.
                </p>


                <h2>11. MODIFICATION AND TERMINATION</h2>

                <ol>
                    <li>We reserve the right to alter the Agreement at any time as we expand and improve the Service. If the alterations constitute a material change to the Agreement, we will notify you directly through the App. What constitutes “material change” shall be determined at our sole discretion, in good faith and using common sense and reasonable judgement.</li>
                    <li>If the Agreement or your account is terminated for any reason, the agreement will continue to apply and be binding upon you in respect to your prior use of the Service and anything connected with or relating to such use.</li>
                </ol>


                <h2>12. INDEMNITY</h2>

                <p>
                    You agree to defend, indemnify and hold the Company, its affiliates, suppliers, licensors, co-branders, information providers, service providers, partners, directors, officers, employees, agents, representatives, and their respective successors and assigns, harmless from and against all liabilities, claims, costs and expenses, in connection with your:
                </p>
                <ul>
                    <li>Violation of any of the provisions of the Agreement;</li>
                    <li>Use of, or inability to use, the Service or the beauty services provided; or </li>
                    <li>Use of, reliance on, placement, posting or transmission of beauty services available on or through the Service.</li>
                </ul>


                <h2>13. GOVERNING LAW AND DISPUTE RESOLUTION</h2>

                <ol>
                    <li>The Company controls and operates the Service from its offices in Nairobi, Kenya, and is subject to the applicable laws and jurisdiction of the Republic of Kenya. If you use this Service from outside Kenya, you are entirely responsible for compliance with applicable laws in your jurisdiction.</li>
                    <li>In case of any disputes, you agree to contact us and schedule a meeting to mediate and seek an amicable settlement.</li>
                </ol>


                <h2>14. MISCELLANEOUS</h2>

                    <p>
                        If any provision of the Agreement is declared by a court of competent jurisdiction to be illegal, unenforceable or void, it shall be severed from the Agreement, and such court shall replace the original provision with a valid and enforceable provision that will achieve the same purposes of the original provision.
                    </p>
                    <p>You and the Company are independent contractors and no agency, partnership, joint venture, employment nor franchise relationship is intended or created by the Agreement or your use of the Service.</p>
                    <p>The Company may assign the Agreement and its rights and obligations under this agreement without your consent. Any rights not expressly granted by the Agreement are reserved to the Company.</p>


            </div>
        )
    }
}
