import React, { Component } from 'react'


export default class Step extends Component {
    render() {
        return (
            <div className='step_container'>
                <div className="step">
                    <div style = {{backgroundColor: this.props.color}} className="step_image">
                        <img src={require(`../img/${this.props.img}.png`)} alt="" />
                    </div>
                    <div className="step_description_container">
                        <div className="step_description">
                            <h2>Step {this.props.index}</h2>
                            <h1>{this.props.title}</h1>
                            <p>{this.props.description}
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
