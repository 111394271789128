import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Changed from useNavigate

const AccountDeletion = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory(); // Changed from useNavigate
  const auth = getAuth();

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      setIsDeleting(true);
      try {
        // Step 1: Sign in to get the user's email
        const apiResponse = await fetch('https://plugg-api.apps.openxcell.dev/api/user/auth/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ phone, password }),
        });

        if (!apiResponse.ok) {
          throw new Error('Failed to authenticate with the API');
        }

        const apiData = await apiResponse.json();
        const userEmail = apiData.data.email;

        if (!userEmail) {
          throw new Error('Email not found in API response');
        }

        // Step 2: Re-authenticate with Firebase
        await signInWithEmailAndPassword(auth, userEmail, password);

        const user = auth.currentUser;
        if (user) {
          // Step 3: Delete user data from your API
          const deleteResponse = await fetch('https://plugg-api.apps.openxcell.dev/api/user/delete-account', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${apiData.token}`,
              'Content-Type': 'application/json',
            },
          });

          if (!deleteResponse.ok) {
            throw new Error('Failed to delete account data from API');
          }

          // Step 4: Delete the user from Firebase Authentication
          await user.delete();

          alert('Your account has been successfully deleted.');
          history.push('/'); // Changed from navigate('/')
        } else {
          throw new Error('No user is currently signed in.');
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        alert(`Failed to delete account: ${error.message}`);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <div>
      <h1>Delete Account</h1>
      <p>Warning: This action is irreversible. All your data will be permanently deleted.</p>
      <input
        type="tel"
        placeholder="Phone Number"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleDeleteAccount} disabled={isDeleting || !phone || !password}>
        {isDeleting ? 'Deleting...' : 'Delete My Account'}
      </button>
    </div>
  );
};

export default AccountDeletion;
