import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import general from "../img/faq_general.png";
import stylist from "../img/faq_stylist.png";
import user from "../img/faq_user.png";
import Faq from "./faq";
import data from "../data/faq_data.json";
import anime from "animejs";

function search(term) {
  return function (faq) {
    return faq.title.toLowerCase().includes(term.toLowerCase()) || !term;
  };
}

export default class Faqs extends Component {
  constructor(props) {
    super(props);
    this.updateSelector = this.updateSelector.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.selectedRef = React.createRef();
    this.faqReveal = anime.timeline({
      easeing: "easeOutExpo",
      autoplay: false,
    });
    this.state = {
      selected: "about",
      term: "",
      data: data,
      category: "General",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  updateSelector(selected) {
    this.setState({
      selected,
      category:
        selected === "about"
          ? "General"
          : selected === "users"
          ? "Users"
          : "Hair Stylists",
      term: "",
    });
  }

  searchHandler(e) {
    this.setState({ term: e.target.value });
  }

  render() {
    const { term, data, selected, category } = this.state;

    return (
      <div className="faqs_container">
        <Helmet>
          <title>FAQs</title>
          <meta name="description" content="Frequently Asked Questions" />
        </Helmet>
        <div className="faq_header">
          <h1>FAQs</h1>
          <p>Need help? We've got you covered</p>
        </div>
        <div className="faq_selectors_container">
          {/* <Link
            onClick={() => {
              this.updateSelector("about");
            }}
          > */}
          <div
            style={selected === "about" ? { backgroundColor: "#B05A83" } : {}}
            className="faq_selector_container"
          >
            <div className="faq_selector">
              <div className="faq_image">
                <img src={general} alt="" />
              </div>
              <h2>General</h2>
            </div>
          </div>
          {/* </Link> */}

          {/* <Link onClick={() => { this.updateSelector('users') }} >
                        <div style={selected === 'users' ? { backgroundColor: '#B05A83' } : {}} className="faq_selector_container">
                            <div className="faq_selector">
                                <div className="faq_image">
                                    <img src={user} alt="" />
                                </div>
                                <h2>Users</h2>
                            </div>
                        </div>
                    </Link>

                    <Link onClick={() => { this.updateSelector('stylist') }} >
                        <div style={selected === 'stylist' ? { backgroundColor: '#B05A83' } : {}} className="faq_selector_container">
                            <div className="faq_selector">
                                <div className="faq_image">
                                    <img src={stylist} alt="" />
                                </div>
                                <h2>Hair Stylists</h2>
                            </div>
                        </div>
                    </Link> */}
        </div>
        <div className="search">
          <input
            placeholder={`Search ${category}`}
            value={term}
            onChange={this.searchHandler}
            type="text"
          />
        </div>
        <div ref={this.selectedRef} className="selected_container">
          {data.filter(search(term)).map((faq) => {
            if (faq.category === selected) {
              return (
                <Faq
                  index={faq.index}
                  title={faq.title}
                  description={faq.description}
                />
              );
            }
          })}
        </div>
      </div>
    );
  }
}
