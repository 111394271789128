import React, { Component } from "react";
import axios from "axios";

export default class Article extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { img, category, title } = this.props;
    //const {img} = this.state

    return (
      <div className="article_container">
        <div className="article_image">
          <img src={img} alt="" />
        </div>
        <div className="article_title_container">
          <h3>{category}</h3>
          <h2>{title}</h2>
        </div>
      </div>
    );
  }
}
