import React, { Component } from 'react'
import Feature from './feature'
import {Helmet} from 'react-helmet'


export default class Features extends Component {
    componentDidMount(){
        window.scrollTo(0,0)
    }
    render() {
        return (
            <div className='features_container'>
                <Helmet>
                    <title>Plug Features</title>
                    <meta 
                        name='keywords'
                        content = 'Personal Beauty Space, Keep up with Friends,  Discover Hairstyles, Plug It!'
                    />
                </Helmet>
                <div className="features">
                    <Feature
                        icon='board'
                        class='light_pink'
                        category='board'
                        title='Your personal beauty space'
                        description='Upload images of hairstyle inspirations you’d love to try out. You can also view your friends’ boards to see what inspires there looks.'
                    />
                    <Feature
                        icon='feed'
                        category='Feed'
                        title='Keep up with friends! '
                        description='Peep what hairstyles your friends are currently uploading. Like, share and add them to your board.'
                    />
                    <Feature
                        icon='explore'
                        category='Explore'
                        title='Discover more beautiful and creative hairstyles'
                        description='Scroll through what other black queens have uploaded as their hair inspo. Like them? Just add to your board.'
                    />
                    <Feature
                        icon='imgsearch'
                        class='light_pink'
                        category='image search'
                        title='Plug it!'
                        description='What do you do with all these images of beautiful hairstyles? Plug it!
                        Plug<span class="plug_logo"></span> any image on the app and get results of stylists who can bring your hairstyle inspirations to life. 
                        '
                    />
                    
                    <Feature
                        icon='filter'
                        class='light_pink'
                        category='Filter'
                        title='It’s all about the details '
                        description='Filter stylists according to location, price and service. Choose a stylist at your own comfortable price, in a convenient location AND get it done in a salon or the comfort of your own home, you decide.
                        '
                    />
                    <Feature
                        icon='services'
                        category='Services'
                        title='Select what you want '
                        description='For the woman who knows exactly what she wants, we got you. Simply select the hairstyle you wish to achieve and match with the right stylist for you. 
                        '
                    />
                    <Feature
                        icon='book'
                        category='Book'
                        title='Connect with your stylist'
                        description='Go through more details of the hairstyle, either bookmark the stylist as you plan ahead or book an appointment and connect with the stylist right away. Rate the stylist after the appointment.
                        '
                    />
                    <Feature
                        icon='recommend'
                        class='light_pink'
                        category='Recommendations'
                        title='Sharing is caring'
                        description='Recommend hairstyles and stylists to your friends. Fun Fact: Friends that look cute together, stay together.'
                    />
                </div>
            </div>
        )
    }
}
