import React, { useEffect } from "react";
import ReactLoading from "react-loading";
import { Redirect } from "react-router-dom";

export default function Download() {
  useEffect(() => {
    //redirection is happening here
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.plugltd.plug_user";
  }, []);

  //   return <Redirect to="https://stackabuse.com/redirects-in-react-router/" />;

  return (
    <div
      style={{
        marginTop: "100px",
        marginBottom: "100px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type={"bubbles"}
        color={"#E1A7C3"}
        height={"50px"}
        width={"50px"}
      />
      <div style={{}}>Redirecting to the Appstore...</div>
    </div>
  );
}
