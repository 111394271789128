import React, { Component } from "react";
import Steps from "./steps";
import Newsroom from "./newsroom";
import phone from "../img/plug_phone2.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import audioOn from "../img/audio_on.png";
import audioOff from "../img/audio_off.png";
import ReactLoading from "react-loading";
//import { firebaseAnalytics } from "../firebase";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audio: true,
      play: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    //firebaseAnalytics.logEvent("Hompage Visits");
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Plug: Discover & Book Hairstyles | Sign up as a Stylist</title>
          <meta
            name="description"
            content="Plug is a beauty app for black women to discover, share and achieve hairstyle inspirations by connecting them to the right stylists."
          />
        </Helmet>
        <section className="hero_container">
          <div className="hero">
            <div className="hero_text">
              <h1>DISCOVER & ACHIEVE</h1>
              <h2>HAIRSTYLE INSPIRATIONS,</h2>
              <h2 className="barry effort">Effortlessly.</h2>
              <p>
                Plug is a beauty app for black women to discover, share and
                achieve hairstyle inspirations by connecting them to the right
                stylists.
              </p>
              <p>Look and feel your best effortlessly.</p>
              <div className="download">
                <div>
                  <i className="fas fa-check"></i>Android
                </div>
                <div>
                  <i className="fas fa-check"></i>iPhone
                </div>

                <div className="coming_soon">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.plug_partner"
                    target="blank"
                    className="button large_button download_btn"
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>
            <div className="hero_phone">
              {/* <img src={phone} alt="" /> */}
              <ReactPlayer
                height={"90%"}
                playing={true}
                loop={true}
                muted={this.state.audio}
                url="https://firebasestorage.googleapis.com/v0/b/plug-website.appspot.com/o/plug%20video_7.mp4?alt=media&token=a3efddd2-1367-40a8-932e-6875aa4bebeb"
              />
              <img
                className="audio"
                src={!this.state.audio ? audioOn : audioOff}
                alt=""
                onClick={() => this.setState({ audio: !this.state.audio })}
              />
            </div>
          </div>
        </section>
        {/* END OF HERO */}

        <Steps />
        {/* END OF STEPS */}

        <div className="explore_btn">
          <Link to="/features">
            <div className="button large_btn">Explore Features</div>
          </Link>
        </div>
        <Newsroom />
        {/* END OF NEWSROOM */}
      </div>
    );
  }
}
