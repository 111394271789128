import React, { Component } from 'react'
import anime from 'animejs'

export default class Faq extends Component {
    constructor(props){
        super(props)
        this.descriptionRef = React.createRef()
        this.buttonRef = React.createRef()
        this.toggle  = this.toggle.bind(this)
        this.state = {
            open: false
        }
    }
    componentDidMount(){
        anime({
            targets: this.refs.faq_container,
            opacity: [0,1],
            easing: 'easeInExpo',
            duration: 1000
        })
    }

    toggle(){
        !this.state.open ? 
        this.descriptionRef.current.style.display = 'block' :
        this.descriptionRef.current.style.display = 'none'

        !this.state.open ? 
        this.buttonRef.current.style.transform = 'rotate(90deg)' :
        this.buttonRef.current.style.transform = 'rotate(0deg)'

        this.setState({open: !this.state.open})
    }
    render() {
        return (
            <div ref = 'faq_container' className='faq_container'>
                <div onClick = {this.toggle} className="faq_title">
                    <h3>{this.props.title}</h3>
                    <div ref = {this.buttonRef}  className="faq_button"></div>
                </div>
                <div ref = {this.descriptionRef}className="faq_description">
                    <p dangerouslySetInnerHTML={ {__html: this.props.description}} />
                </div>
            </div>
        )
    }
}
