import React, { Component } from "react";
import { Helmet } from "react-helmet";
import firebase from "../firebase";
import emailjs from "emailjs-com";

export default class Apply extends Component {
  constructor(props) {
    super(props);
    //functuion declaration
    this.sendMessage = this.sendMessage.bind(this);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      submit: "Submit",
      location: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    //firebaseAnalytics.logEvent("Apply page visit");
  }
  clearFields() {
    this.setState({
      email: "",
      phone: "",
      submit: "Submit",
      name: "",
      message: "",
      location: "",
    });
  }

  sendEmail() {
    emailjs
      .send(
        "infoplug_sypgz14",
        "template_kh1SrUOV",
        {
          name: this.state.name,
          email: this.state.email,
        },
        "user_bC9ER3NZv0tI1RDjytBDw"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  sendMessage(e) {
    e.preventDefault();

    this.setState({ submit: "sending..." });
    //firebase code execution
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true,
    });
    const userRef = db.collection("Emails").add({
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      location: this.state.location,
      message: this.state.message,
    });

    //send email for confirmation
    this.sendEmail();

    setTimeout(() => {
      this.clearFields();
      this.setState({ submit: "Sent" });
    }, 2000);
    setTimeout(() => {
      this.setState({ submit: "Submit" });
    }, 3000);

    console.log("message sent");
  }
  render() {
    return (
      <div className="contact_container">
        <Helmet>
          <title>Stylist Application</title>
          <meta
            name="description"
            content="Reach out to us for any questions"
          />
        </Helmet>
        <div className="contact">
          <h1>Stylist Application</h1>
          <form onSubmit={this.sendMessage} action="submit">
            <div className="namephone">
              <input
                value={this.state.name}
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
                placeholder="Stylist/Salon Name"
                type="text"
                required
              />
              <input
                value={this.state.phone}
                onChange={(e) => {
                  this.setState({ phone: e.target.value });
                }}
                placeholder="Number (e.g 0712345678)"
                type="tel"
                pattern="[0-9]{4}[0-9]{6}"
                required
              />
            </div>
            <div className="emailocation">
              <input
                value={this.state.email}
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
                type="email"
                placeholder="Email Address"
              />
              <input
                value={this.state.location}
                onChange={(e) => {
                  this.setState({ location: e.target.value });
                }}
                placeholder="Location"
                type="text"
                required
              />
            </div>
            <input
              id="message"
              value={this.state.message}
              onChange={(e) => {
                this.setState({ message: e.target.value });
              }}
              type="text"
              placeholder="Message"
            />
            <button type="submit" className="button">
              {this.state.submit}
            </button>
          </form>
        </div>
      </div>
    );
  }
}
