import React, { Component } from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import About from "./about";
import AccountDeletion from "./account-deletion";
import Apply from "./apply";
import ArticleOpen from "./articleopen";
import Contact from "./contact";
import Download from "./Download";
import Faqs from "./faqs";
import Features from "./features";
import Footer from "./footer";
import Header from "./header";
import Home from "./home";
import Policy from "./policy";
import Salonist from "./salonist";
import Terms from "./terms";

import "../styles/app.css";

const handlePageChange = () => {
  window.scrollTo(0, 0);
};

export default class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Header />
          {/* CONTENT */}
          <Switch>
            <Route
              path="/articleopen/:article_id"
              render={(props) => <ArticleOpen {...props} isAuthed={true} />}
            />
            <Route
              path="/salonist"
              component={Salonist}
              onEnter={handlePageChange}
            />
            <Route
              path="/contact"
              component={Contact}
              onEnter={handlePageChange}
            />
            <Route path="/apply" component={Apply} onEnter={handlePageChange} />
            <Route path="/terms" component={Terms} onEnter={handlePageChange} />
            <Route
              path="/policy"
              component={Policy}
              onEnter={handlePageChange}
            />
            <Route
              path="/account-deletion"
              component={AccountDeletion}
              onEnter={handlePageChange}
            />
            <Route path="/about" component={About} onEnter={handlePageChange} />
            <Route
              path="/user-app"
              component={Download}
              onEnter={handlePageChange}
            />
            <Route
              path="/features"
              component={Features}
              onEnter={handlePageChange}
            />
            <Route path="/faqs" component={Faqs} onEnter={handlePageChange} />
            <Route path="/" component={Home} onEnter={handlePageChange} />
          </Switch>
          {/* CONTENT END */}
          <Footer />
        </div>
      </Router>
    );
  }
}
