import React, { Component } from "react";
import Step from "./step";
import navLeft from "../img/nav_left.png";
import navRightWhite from "../img/nav_rightwhite.png";
import navLeftWhite from "../img/nav_leftwhite.png";
import anime from "animejs";

export default class Steps extends Component {
  constructor(props) {
    super(props);
    this.animate = this.animate.bind(this);
    this.nextRef = React.createRef();
    this.stepContainerRef = React.createRef();
    this.tl = anime.timeline({
      easing: "easeInOutQuad",
      autoplay: false,
      loop: 5,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const track = document.querySelector(".step_track_container");
    const slides = Array.from(track.children);
    const next = document.querySelector(".next_btn");
    const prev = document.querySelector(".prev_btn");
    const dotNav = document.querySelector(".step_nav");
    const dots = Array.from(dotNav.children);
    const slideSize = slides[0].getBoundingClientRect();
    const slideWidth = slideSize.width;
    track.style.transition = "transform 0.9s cubic-bezier(0, 0.55, 0.45, 1)";

    slides.forEach((slide, index) => {
      slide.style.position = "absolute";
      slide.style.left = slideWidth * index + "px";
    });
    //set current slide to first slide
    slides[0].classList.add("current_step");

    //movetoSlide function
    const movetoSlide = (track, currentSlide, targetSlide) => {
      const wrapper_transform = `translate(-${targetSlide.style.left})`;
      track.style.transform = wrapper_transform;
      currentSlide.classList.remove("current_step");
      targetSlide.classList.add("current_step");
    };
    //update dots
    const updateDots = (currentDot, targetDot) => {
      currentDot.classList.remove("current_indicator");
      targetDot.classList.add("current_indicator");
    };
    //hide or show arrows
    const hideShowArrows = (slides, targetIndex, next, prev) => {
      if (targetIndex === 0) {
        prev.classList.add("is_hidden");
        next.classList.remove("is_hidden");
      } else if (targetIndex === slides.length - 1) {
        next.classList.add("is_hidden");
        prev.classList.remove("is_hidden");
      } else {
        next.classList.remove("is_hidden");
        prev.classList.remove("is_hidden");
      }
    };
    //on next click
    next.addEventListener("click", (e) => {
      const currentSlide = track.querySelector(".current_step");
      const targetSlide = currentSlide.nextElementSibling;
      const currentDot = dotNav.querySelector(".current_indicator");
      const targetDot = currentDot.nextElementSibling;
      const targetIndex = slides.findIndex((slide) => slide === targetSlide);
      //move this shit
      movetoSlide(track, currentSlide, targetSlide);
      updateDots(currentDot, targetDot);
      hideShowArrows(slides, targetIndex, next, prev);
    });
    //on prevClick
    prev.addEventListener("click", (e) => {
      const currentSlide = track.querySelector(".current_step");
      const targetSlide = currentSlide.previousElementSibling;
      const currentDot = dotNav.querySelector(".current_indicator");
      const targetDot = currentDot.previousElementSibling;
      const targetIndex = slides.findIndex((slide) => slide === targetSlide);

      //move this shit but ler
      movetoSlide(track, currentSlide, targetSlide);
      updateDots(currentDot, targetDot);
      hideShowArrows(slides, targetIndex, next, prev);
    });
    // we are doing the dots thing
    dotNav.addEventListener("click", (e) => {
      let targetDot = e.target.closest(".step_nav_indicator");
      if (!targetDot) return;

      const currentSlide = track.querySelector(".current_step");
      const currentDot = dotNav.querySelector(".current_indicator");
      const targetIndex = dots.findIndex((dot) => dot === targetDot);
      const targetSlide = slides[targetIndex];
      movetoSlide(track, currentSlide, targetSlide);
      updateDots(currentDot, targetDot);
      hideShowArrows(slides, targetIndex, next, prev);
    });
    //next button signal function
    window.addEventListener("scroll", this.animate);

    this.tl
      .add({
        targets: ".next_btn",
        scale: [1, 2],
      })
      .add({
        targets: ".next_btn",
        scale: [2, 1],
      });
  }
  animate() {
    const scroll = window.scrollY;
    const stepContainer =
      this.stepContainerRef.current?.getBoundingClientRect();

    scroll > stepContainer.top + 0.3 * window.innerHeight &&
    this.stepContainerRef
      ? console.log("hello")
      : console.log("not hello");
    this.tl.play();
    window.removeEventListener("scroll", this.animate);
  }
  render() {
    return (
      <div className="steps_container">
        <div ref={this.nextRef} className="next_btn">
          <img src={navRightWhite} alt="" />
        </div>
        <div className="prev_btn is_hidden">
          <img src={window.innerWidth > 760 ? navLeft : navLeftWhite} alt="" />
        </div>
        <div ref={this.stepContainerRef} className="step_track_container">
          <Step
            img="step1"
            color="#f1dce5"
            index="One"
            title="Upload. Share. Discover"
            description="upload images of your hairstyle inspirations, share with friends and explore ideas."
          />
          <Step
            img="step2"
            color="#f1dce5"
            index="Two"
            title="Plug and Match It"
            description="Plug any hairstyle image to match with the right hairstylist for you and your needs."
          />
          <Step
            img="step3"
            color="#f1dce5"
            index="Three"
            title="Book It"
            description="Connect with the stylist and confidently book an appointment based on your preferred hairstyle, location and price.
                        "
          />
        </div>
        <div className="step_nav">
          <div className="step_nav_indicator current_indicator"></div>
          <div className="step_nav_indicator"></div>
          <div className="step_nav_indicator"></div>
          <div className="step_nav_indicator"></div>
        </div>
      </div>
    );
  }
}
